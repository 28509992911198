import fetch from "auth/FetchInterceptor"
import { AUTH_TOKEN } from "constants/AuthConstant"

const ProfessionalService = {}
ProfessionalService.addProfessional = function (data) {
  return fetch({
    method: "post",
    url: "api/companies/create",
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}

ProfessionalService.UpdateProfessional = function (id, data) {
  return fetch({
    method: "put",
    url: `api/companies/update/${id}`,
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}

ProfessionalService.getProfessionals = function (page = 1) {
  return fetch({
    method: "get",
    url: `api/companies/getAll?page=${page}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}
ProfessionalService.filter = function (page, status, domains, value, type) {
  let url = `api/companies/getAll?page=${page}&status=${status}&type=${type}&companyName=${value}&domain=${domains}`
  if (!status && !type) {
    url = `api/companies/getAll?page=${page}&companyName=${value}&domain=${domains}`
  } else if (!status) {
    url = `api/companies/getAll?page=${page}&type=${type}&companyName=${value}&domain=${domains}`
  } else if (!type) {
    url = `api/companies/getAll?page=${page}&status=${status}&companyName=${value}&domain=${domains}`
  } else {
    url = `api/companies/getAll?page=${page}&status=${status}&type=${type}&companyName=${value}&domain=${domains}`
  }

  return fetch({
    method: "get",
    url: url,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}

ProfessionalService.getOneProfessional = function (id) {
  return fetch({
    method: "get",
    url: `api/companies/getById/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}
ProfessionalService.deleteProfessional = function (id) {
  return fetch({
    method: "delete",
    url: `api/companies/deleteById/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}

ProfessionalService.validate = function (id) {
  return fetch({
    method: "post",
    url: `api/user/validateAccount/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}
ProfessionalService.ban = function (id, reason) {
  return fetch({
    method: "post",
    url: `api/user/bannedAccount/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
    data: {
      argument: reason,
    },
  })
}
ProfessionalService.countPendingCompanies = function () {
  return fetch({
    method: "get",
    url: "api/companies/countPending",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}

ProfessionalService.countCompanies = function () {
  return fetch({
    method: "get",
    url: "api/companies/countCompanies",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}

ProfessionalService.getVipRequest = function () {
  return fetch({
    method: "get",
    url: "api/becomeVIP/getPendingRequest",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}

ProfessionalService.validateVipRequest = function (id) {
  return fetch({
    method: "put",
    url: `api/becomeVIP/validate/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}

ProfessionalService.refuseVipRequest = function (id) {
  return fetch({
    method: "put",
    url: `api/becomeVIP/refuse/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}

ProfessionalService.ChangeType = function (id, type) {
  return fetch({
    method: "put",
    url: `api/companies/changeType/${id}`,
    data: {
      type: type,
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}
ProfessionalService.countByCountry = function () {
  return fetch({
    method: "get",
    url: `api/companies/countByCountry`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}

export default ProfessionalService
