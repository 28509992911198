// professionalSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import ProfessionalService from "services/ProfessionalService"

export const getAllProfessionals = createAsyncThunk(
  "professionals/getAllProfessionals",
  async (page = 1, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setCurrentPage(page))
      const response = await ProfessionalService.getProfessionals(page)
      dispatch(clearStates())
      return response.data
    } catch (error) {
      return rejectWithValue(error.response || "Erreur")
    }
  }
)

export const searchProfessionals = createAsyncThunk(
  "professionals/search",
  async (
    { page = 1, status, domains, value, type },
    { rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(setCurrentPage(page))
      const response = await ProfessionalService.filter(
        page,
        status,
        domains,
        value,
        type
      )
      dispatch(clearStates())
      return response.data
    } catch (error) {
      return rejectWithValue(error.response || "Erreur")
    }
  }
)

export const createProfessional = createAsyncThunk(
  "professional/createProfessional",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ProfessionalService.addProfessional(data)
      return response
    } catch (error) {
      return rejectWithValue(error.response || "Erreur")
    }
  }
)

export const updateProfessional = createAsyncThunk(
  "professionals/updateProfessional",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await ProfessionalService.UpdateProfessional(id, data)
      return { id, updatedJob: response.data }
    } catch (error) {
      return rejectWithValue(error.response || "Erreur")
    }
  }
)

export const deleteProfessional = createAsyncThunk(
  "professionals/deleteProfessional",
  async (id, { rejectWithValue }) => {
    try {
      await ProfessionalService.deleteProfessional(id)
      return id
    } catch (error) {
      return rejectWithValue(error.response || "Erreur")
    }
  }
)

export const fetchOneProfessional = createAsyncThunk(
  "professionals/fetchOneProfessional",
  async (id, { rejectWithValue }) => {
    try {
      const response = await ProfessionalService.getOneProfessional(id)
      return response.data
    } catch (error) {
      return rejectWithValue(error.response || "Erreur")
    }
  }
)
export const validateProfessional = createAsyncThunk(
  "professionals/validate",
  async (id, { rejectWithValue }) => {
    try {
      const response = await ProfessionalService.validate(id)
      return response.data
    } catch (error) {
      return rejectWithValue(error.response || "Erreur")
    }
  }
)
export const banProfessional = createAsyncThunk(
  "professionals/ban",
  async ({ id, reason }, { rejectWithValue }) => {
    try {
      const response = await ProfessionalService.ban(id, reason)
      return response.data
    } catch (error) {
      return rejectWithValue(error.response || "Erreur")
    }
  }
)
export const countPendingCompanies = createAsyncThunk(
  "professionals/countPending",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ProfessionalService.countPendingCompanies()
      return response.data // Assuming the response returns the count directly
    } catch (error) {
      return rejectWithValue(error.response || "Erreur")
    }
  }
)

export const getCountCompanies = createAsyncThunk(
  "professionals/countCompanies",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ProfessionalService.countCompanies()
      return response.data // Assuming the response returns the count directly
    } catch (error) {
      return rejectWithValue(error.response || "Erreur")
    }
  }
)

export const getVipRequest = createAsyncThunk(
  "professionals/getVipRequest",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ProfessionalService.getVipRequest()
      return response.data
    } catch (error) {
      return rejectWithValue(error.response || "Erreur")
    }
  }
)

export const validateVipRequest = createAsyncThunk(
  "professionnal/validatVipRequest",
  async (id, { rejectWithValue }) => {
    try {
      const response = await ProfessionalService.validateVipRequest(id)
      return response.data
    } catch (error) {
      return rejectWithValue(error.response || "Erreur")
    }
  }
)

export const refuseVipRequest = createAsyncThunk(
  "professionnal/refuseVipRequest",
  async (id, { rejectWithValue }) => {
    try {
      const response = await ProfessionalService.refuseVipRequest(id)
      return response.data
    } catch (error) {
      return rejectWithValue(error.response || "Erreur")
    }
  }
)

export const ChangeType = createAsyncThunk(
  "professionnal/changeType",
  async ({ id, type }, { rejectWithValue }) => {
    try {
      const response = await ProfessionalService.ChangeType(id, type)
      return response.data
    } catch (error) {
      return rejectWithValue(error.response || "Erreur")
    }
  }
)

export const countCompaniesByCountry = createAsyncThunk(
  "professionnal/countCompaniesByCountry",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ProfessionalService.countByCountry()
      return response.data
    } catch (error) {
      return rejectWithValue(error.response || "Erreur")
    }
  }
)

const initialState = {
  professionals: [],
  professional: {},
  loading: false,
  error: null,
  searchStatus: null,
  searchDomains: [],
  searchValue: [],
  totalItems: null,
  numberOfPending: null,
  currentPage: 1,
  searchType: null,
  vipRequest: null,
  numberOfVipRequest: 0,
  numberOfViPCompanies: null,
  numberOfBasicCompanies: null,
  companiesByCountry: null,
}

const professionalSlice = createSlice({
  name: "professionals",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true
    },
    hideLoading: (state, action) => {
      state.loading = false
    },
    hideError: (state, action) => {
      state.error = null
    },
    clearStates: (state) => {
      // state.currentPage = 1
      state.professionals = []
      state.totalItems = null
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload
    },
    setsearchStatus: (state, action) => {
      state.searchStatus = action.payload
    },
    setSearchDomains: (state, action) => {
      state.searchDomains = action.payload
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    setSearchType: (state, action) => {
      state.searchType = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch professionals
      .addCase(getAllProfessionals.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getAllProfessionals.fulfilled, (state, action) => {
        state.loading = false
        state.professionals = action.payload.data
        state.totalItems = action.payload.totalItems
      })
      .addCase(getAllProfessionals.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })

      .addCase(searchProfessionals.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(searchProfessionals.fulfilled, (state, action) => {
        state.loading = false
        state.professionals = action.payload.data
        state.totalItems = action.payload.totalItems
      })
      .addCase(searchProfessionals.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })

      // Create Professional
      .addCase(createProfessional.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(createProfessional.fulfilled, (state, action) => {
        state.professionals.push(action.payload)
        state.loading = false
        state.error = null
      })
      .addCase(createProfessional.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      // Update professional
      .addCase(updateProfessional.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(updateProfessional.fulfilled, (state, action) => {
        const { professionalId, updatedJob } = action.payload
        const index = state.professionals.findIndex(
          (professional) => professional.id === professionalId
        )
        if (index !== -1) {
          state.professionals[index] = {
            ...state.professionals[index],
            ...updatedJob,
          }
        }
        state.loading = false
      })
      .addCase(updateProfessional.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      // Delete professional
      .addCase(deleteProfessional.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(deleteProfessional.fulfilled, (state, action) => {
        const professionalId = action.payload
        state.professionals = state.professionals.filter(
          (professional) => professional._id !== professionalId
        )
        state.loading = false
        state.error = null
        state.totalItems = state.totalItems - 1
      })
      .addCase(deleteProfessional.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

      .addCase(fetchOneProfessional.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchOneProfessional.fulfilled, (state, action) => {
        state.loading = false
        state.professional = action.payload
        state.error = null
      })
      .addCase(fetchOneProfessional.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(validateProfessional.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(validateProfessional.fulfilled, (state, action) => {
        state.loading = false

        state.error = null
      })
      .addCase(validateProfessional.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(banProfessional.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(banProfessional.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
      })
      .addCase(banProfessional.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(countPendingCompanies.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(countPendingCompanies.fulfilled, (state, action) => {
        state.loading = false
        state.numberOfPending = action.payload.numberofPendingCompanies
      })
      .addCase(countPendingCompanies.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

      .addCase(getCountCompanies.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getCountCompanies.fulfilled, (state, action) => {
        state.loading = false
        state.numberOfViPCompanies = action.payload.numberOfViPCompanies
        state.numberOfBasicCompanies = action.payload.numberOfBasicCompanies
      })
      .addCase(getCountCompanies.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

      .addCase(getVipRequest.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getVipRequest.fulfilled, (state, action) => {
        state.loading = false
        state.vipRequest = action.payload.requests
        state.numberOfVipRequest = action.payload.count
      })
      .addCase(getVipRequest.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

      .addCase(validateVipRequest.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(validateVipRequest.fulfilled, (state, action) => {
        state.loading = false
        state.vipRequest = state.vipRequest.filter(
          (request) => request._id !== action.payload.request._id
        )
      })
      .addCase(validateVipRequest.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(refuseVipRequest.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(refuseVipRequest.fulfilled, (state, action) => {
        state.loading = false
        state.vipRequest = state.vipRequest.filter(
          (request) => request._id !== action.payload.request._id
        )
      })
      .addCase(refuseVipRequest.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

      .addCase(ChangeType.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(ChangeType.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
      })
      .addCase(ChangeType.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

      .addCase(countCompaniesByCountry.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(countCompaniesByCountry.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.companiesByCountry = action.payload
      })
      .addCase(countCompaniesByCountry.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  },
})
export default professionalSlice.reducer
export const {
  showLoading,
  hideLoading,
  hideError,
  clearStates,
  setSearchValue,
  setsearchStatus,
  setSearchDomains,
  setCurrentPage,
  setSearchType,
} = professionalSlice.actions
