import fetch from "auth/FetchInterceptor"
import { AUTH_TOKEN } from "constants/AuthConstant"

const ComRelationService = {}

ComRelationService.getRelations = function (
  page,
  status,
  companyName,
  clientName
) {
  let url = `/api/companyRelation/getAll?page=${page}&limit=2`

  if (status) {
    url += `&status=${encodeURIComponent(status)}`
  }
  if (companyName) {
    url += `&companyName=${encodeURIComponent(companyName)}`
  }
  if (clientName) {
    url += `&fullName=${encodeURIComponent(clientName)}`
  }

  return fetch({
    method: "get",
    url: url,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}

ComRelationService.valider = function (id) {
  return fetch({
    method: "post",
    url: `/api/companyRelation/validate/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}

ComRelationService.refuser = function (id) {
  return fetch({
    method: "post",
    url: `/api/companyRelation/refuse/${id}`,

    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}

ComRelationService.countPendingRelations = function () {
  return fetch({
    method: "get",
    url: `/api/companyRelation/countPendingRelations`,

    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}

export default ComRelationService
