import React from "react"
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig"

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
]

export const protectedRoutes = [
  {
    key: "Home",
    path: `${APP_PREFIX_PATH}/home`,
    component: React.lazy(() => import("views/app-views/acceuil")),
  },
  {
    key: "dashboard.default",
    path: `${APP_PREFIX_PATH}/dashboards/default`,
    component: React.lazy(() => import("views/app-views/dashboards/default")),
  },
  {
    key: "professionals",
    path: `${APP_PREFIX_PATH}/professionals`,
    component: React.lazy(() => import("views/app-views/professionals")),
  },
  {
    key: "professional.add",
    path: `${APP_PREFIX_PATH}/professionals/add`,
    component: React.lazy(() =>
      import("views/app-views/professionals/professionalAppAdd")
    ),
  },
  {
    key: "professional.edit",
    path: `${APP_PREFIX_PATH}/professionals/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/professionals/professionalAppEdit")
    ),
  },
  {
    key: "managers",
    path: `${APP_PREFIX_PATH}/managers`,
    component: React.lazy(() => import("views/app-views/managers")),
  },
  {
    key: "domains",
    path: `${APP_PREFIX_PATH}/domains`,
    component: React.lazy(() => import("views/app-views/domains")),
  },
  {
    key: "domain.add",
    path: `${APP_PREFIX_PATH}/domains/add`,
    component: React.lazy(() => import("views/app-views/domains/domainAppAdd")),
  },
  {
    key: "domain.edit",
    path: `${APP_PREFIX_PATH}/domains/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/domains/domainAppEdit")
    ),
  },
  {
    key: "claims",
    path: `${APP_PREFIX_PATH}/claims`,
    component: React.lazy(() => import("views/app-views/claims")),
  },
  {
    key: "clients",
    path: `${APP_PREFIX_PATH}/clients`,
    component: React.lazy(() => import("views/app-views/clients")),
  },
  {
    key: "client.add",
    path: `${APP_PREFIX_PATH}/clients/add`,
    component: React.lazy(() => import("views/app-views/clients/clientAppAdd")),
  },
  {
    key: "client.edit",
    path: `${APP_PREFIX_PATH}/clients/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/clients/clientAppEdit")
    ),
  },
  {
    key: "commercial",
    path: `${APP_PREFIX_PATH}/commercials/*`,
    component: React.lazy(() => import("views/app-views/commercial")),
  },
  // {
  //   key: "commercialsRelations",
  //   path: `${APP_PREFIX_PATH}/commercials/relations`,
  //   component: React.lazy(() =>
  //     import("views/app-views/commercial/relationsList")
  //   ),
  // },
  // {
  //   key: "clientCommercials",
  //   path: `${APP_PREFIX_PATH}/commercials/clients`,
  //   component: React.lazy(() =>
  //     import("views/app-views/commercial/clientsList")
  //   ),
  // },

  {
    key: "User.manageAccount",
    path: `${APP_PREFIX_PATH}/mon_compte/*`,
    component: React.lazy(() => import("views/app-views/account")),
  },
  {
    key: "associations",
    path: `${APP_PREFIX_PATH}/associations`,
    component: React.lazy(() => import("views/app-views/associations")),
  },
  {
    key: "association.add",
    path: `${APP_PREFIX_PATH}/associations/add`,
    component: React.lazy(() =>
      import("views/app-views/associations/associationAppAdd")
    ),
  },
  {
    key: "association.edit",
    path: `${APP_PREFIX_PATH}/associations/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/associations/associationAppEdit")
    ),
  },
  {
    key: "donations",
    path: `${APP_PREFIX_PATH}/donations`,
    component: React.lazy(() => import("views/app-views/donations")),
  },
  {
    key: "services",
    path: `${APP_PREFIX_PATH}/services`,
    component: React.lazy(() => import("views/app-views/services")),
  },
  {
    key: "service.add",
    path: `${APP_PREFIX_PATH}/services/add`,
    component: React.lazy(() =>
      import("views/app-views/services/serviceAppAdd")
    ),
  },
  {
    key: "service.edit",
    path: `${APP_PREFIX_PATH}/services/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/services/serviceAppEdit")
    ),
  },
  {
    key: "faqs",
    path: `${APP_PREFIX_PATH}/faqs`,
    component: React.lazy(() => import("views/app-views/faqs")),
  },
  {
    key: "faq.add",
    path: `${APP_PREFIX_PATH}/faqs/add`,
    component: React.lazy(() => import("views/app-views/faqs/faqAppAdd")),
  },
  {
    key: "faq.edit",
    path: `${APP_PREFIX_PATH}/faqs/edit/:id`,
    component: React.lazy(() => import("views/app-views/faqs/faqAppEdit")),
  },
  {
    key: "relationships",
    path: `${APP_PREFIX_PATH}/relationships`,
    component: React.lazy(() => import("views/app-views/relationships")),
  },
  {
    key: "relationship.add",
    path: `${APP_PREFIX_PATH}/relationships/add`,
    component: React.lazy(() =>
      import("views/app-views/relationships/relationshipAppAdd")
    ),
  },
  // {
  //   key: "relationship.edit",
  //   path: `${APP_PREFIX_PATH}/relationships/edit/:id`,
  //   component: React.lazy(() =>
  //     import("views/app-views/relationships/relationshipAppEdit")
  //   ),
  // },
  {
    key: "admins",
    path: `${APP_PREFIX_PATH}/admins`,
    component: React.lazy(() => import("views/app-views/admins")),
  },
  {
    key: "admin.add",
    path: `${APP_PREFIX_PATH}/admins/add`,
    component: React.lazy(() => import("views/app-views/admins/adminAppAdd")),
  },
  {
    key: "admin.edit",
    path: `${APP_PREFIX_PATH}/admins/edit/:id`,
    component: React.lazy(() => import("views/app-views/admins/adminAppEdit")),
  },
  {
    key: "notifications",
    path: `${APP_PREFIX_PATH}/notifications`,
    component: React.lazy(() => import("views/app-views/notifications")),
  },
  {
    key: "notification.add",
    path: `${APP_PREFIX_PATH}/notifications/add`,
    component: React.lazy(() =>
      import("views/app-views/notifications/notificationAppAdd")
    ),
  },
  {
    key: "notification.edit",
    path: `${APP_PREFIX_PATH}/notifications/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/notifications/notificationAppEdit")
    ),
  },
]
