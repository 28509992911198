import fetch from "auth/FetchInterceptor"
import { AUTH_TOKEN } from "constants/AuthConstant"

const ComClientService = {}

ComClientService.getClients = function (page, fullName) {
  let url = `/api/companyRelation/getAllCommercials?page=${page}&limit=2`

  if (fullName) {
    url += `&fullName=${encodeURIComponent(fullName)}`
  }

  return fetch({
    method: "get",
    url: url,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}

export default ComClientService
