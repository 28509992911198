import React, { useEffect } from "react"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import { ThemeSwitcherProvider } from "react-css-theme-switcher"
import store from "./store"
import history from "./history"
import Layouts from "./layouts"
import { THEME_CONFIG } from "./configs/AppConfig"
import "./lang"
import { socket } from "sockets/socket.config"

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
}

function App() {
  useEffect(() => {
    console.log("Socket connected?", socket.connected)
    socket.connect()

    // return () => {
    //   socket.disconnect()
    // }
  }, [])
  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter history={history}>
          <ThemeSwitcherProvider
            themeMap={themes}
            defaultTheme={THEME_CONFIG.currentTheme}
            insertionPoint="styles-insertion-point"
          >
            <Layouts />
          </ThemeSwitcherProvider>
        </BrowserRouter>
      </Provider>
    </div>
  )
}

export default App
