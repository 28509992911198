import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import ComClientService from "services/ComClientService"

const initialState = {
  clients: [],
  loading: false,
  error: null,
  totalItems: null,
  currentPage: 1,
}

export const fetchClients = createAsyncThunk(
  "commercials/fetchClients",
  async ({ page = 1, fullName = null }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setCurrentPage(page))
      const response = await ComClientService.getClients(page, fullName)
      dispatch(clearStates())
      return response.data
    } catch (error) {
      return rejectWithValue(error.response?.data?.data.message || "Erreur")
    }
  }
)

const comClientSlice = createSlice({
  name: "ComClient",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true
    },
    hideLoading: (state, action) => {
      state.loading = false
    },
    hideError: (state, action) => {
      state.error = null
    },

    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },

    clearStates: (state) => {
      state.relations = []
      state.totalItems = null
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchClients.fulfilled, (state, action) => {
        state.clients = action.payload.data
        state.loading = false
        state.error = null
        state.totalItems = action.payload.totalItems
      })
      .addCase(fetchClients.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchClients.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  },
})
export default comClientSlice.reducer
export const {
  showLoading,
  hideLoading,
  hideError,
  setCurrentPage,
  clearStates,
} = comClientSlice.actions
