import { io } from "socket.io-client"
import store from "store"
import { countPendingRelations } from "store/slices/comRelationSlice"
import { countPendingCompanies } from "store/slices/professionalsSlice"

const SOCKET_URL = "http://localhost:5000/"
// const SOCKET_URL = "https://devback.win-guide.com/"

export const socket = io(SOCKET_URL, {
  transports: ["websocket"], // Assurez une connexion stable
})

socket.on("connect", () => {
  console.log("Connected to WebSocket server")
})

socket.on("disconnect", () => {
  console.log("Disconnected from WebSocket server")
})

socket.on("companyWasSent", () => {
  console.log("relation created")
  store.dispatch(countPendingRelations())
})
