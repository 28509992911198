import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import ComRelationService from "services/ComRelationService"

const initialState = {
  relations: [],
  loading: false,
  error: null,
  totalItems: null,
  status: null,
  currentPage: 1,
  pendingRelationsNumber: 0,
}

export const fetchRelations = createAsyncThunk(
  "commercials/fetchRelations",
  async (
    { page = 1, status = null, companyName = null, clientName = null },
    { rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(setCurrentPage(page))
      const response = await ComRelationService.getRelations(
        page,
        status,
        companyName,
        clientName
      )
      dispatch(clearStates())
      return response.data
    } catch (error) {
      return rejectWithValue(error.response?.data?.data.message || "Erreur")
    }
  }
)

export const validerRelations = createAsyncThunk(
  "commercial/validate",
  async (id, { rejectWithValue }) => {
    try {
      const response = await ComRelationService.valider(id)

      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data?.error || "Erreur ")
    }
  }
)

export const refuserRelation = createAsyncThunk(
  "commercial/refuser",
  async (id, { rejectWithValue }) => {
    try {
      const response = await ComRelationService.refuser(id)

      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data?.error || "Erreur ")
    }
  }
)

export const countPendingRelations = createAsyncThunk(
  "commercial/countPending",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ComRelationService.countPendingRelations()
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data?.error || "Erreur ")
    }
  }
)
const comRelationSlice = createSlice({
  name: "ComRelation",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true
    },
    hideLoading: (state, action) => {
      state.loading = false
    },
    hideError: (state, action) => {
      state.error = null
    },

    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },

    clearStates: (state) => {
      state.relations = []
      state.totalItems = null
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchRelations.fulfilled, (state, action) => {
        state.relations = action.payload.data
        state.loading = false
        state.error = null
        state.totalItems = action.payload.totalItems
      })
      .addCase(fetchRelations.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchRelations.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

      .addCase(validerRelations.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
      })

      .addCase(validerRelations.pending, (state) => {
        state.loading = true
        state.error = null
      })

      .addCase(validerRelations.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

      .addCase(refuserRelation.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
      })

      .addCase(refuserRelation.pending, (state) => {
        state.loading = true
        state.error = null
      })

      .addCase(refuserRelation.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

      .addCase(countPendingRelations.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.pendingRelationsNumber = action.payload.count
      })

      .addCase(countPendingRelations.pending, (state) => {
        state.loading = true
        state.error = null
      })

      .addCase(countPendingRelations.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  },
})
export default comRelationSlice.reducer
export const {
  showLoading,
  hideLoading,
  hideError,
  setCurrentPage,

  clearStates,
} = comRelationSlice.actions
